<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-link :to="{ name: 'auth-login' }">
          <img class="logo" :src="logoImage" height="50" alt="" />
        </b-link>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Forgot password V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1">
            {{ $t('auth.forgot_password') }} 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('auth.forgot_password_description') }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group
                label="Email"
                label-for="forgot-password-email"
              >
              <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                  :custom-messages="{ required: $t('validators.required') }"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="email"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
                @click="sendEmail"
                :disabled="isButtonClicked"
              >
                {{ $t('auth.send_reset_link') }}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{ name: 'auth-login' }">
              <feather-icon icon="ChevronLeftIcon" />
              {{ $t('auth.back_to_login') }}
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
import { recoverPassword } from '@/api/routes'

/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { logoImageFunction } from '@/main'

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      email: '',
      //sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      sideImg: require('@/assets/images/pages/login/intro.png'),

      // validation
      required,
      email,
      isButtonClicked: false,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login/intro.png')
        return this.sideImg
      }
      return this.sideImg
    },
    logoImage() {
      logoImageFunction();
    },
  },
  methods: {
    async sendEmail() {
      // Si el botón ya ha sido presionado, no hagas nada
      if (this.isButtonClicked) return;

      // Marca el botón como presionado
      this.isButtonClicked = true;

      if (!this.email) {
        this.makeToast('danger', this.$t('Error'), this.$t('Email is required'));
        return;
      }

      let data = {
        email: this.email
      }

      await this.$http.post(recoverPassword, data).then(response => {
        if (response.data.status == 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t(response.data.msg_code),
              icon: 'EditIcon',
              variant: 'success',
            },
          });

          // Redirigir después de 1 segundo
          setTimeout(() => {
            this.$router.push({ name: 'auth-login' });
          }, 1000);
        } else {
          let title = this.$t(response.data.msg_code)
          if (response.data.status == 403) title = this.$t('auth.forgot_password_error_educa')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: title,
              icon: 'AlertIcon',
              variant: 'danger',
            },
          });
        }
      }).catch(error => {
        this.makeToast('danger', this.$t('Error'), this.$t('error_retry_later'));
      })
    },
    makeToast(variant = null, tit = '', msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.sendEmail()

        }
      })
    },
  },
  created() {
    let URLlang = this.$route.query.lang;
    let langs = ['en', 'es', 'fr', 'pt', 'ca','it','la']
    if (URLlang && langs.includes(URLlang)) {
      this.$i18n.locale = URLlang;
    }
    extend('required', {
      ...required,
      message: this.$t('forms.errors.required'),
    });
    extend('email', {
      ...email,
      message: this.$t('forms.errors.valid_email'),
    });
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

@media screen and (orientation:landscape) {
  .logo {
    margin-bottom: 20px;
    height: 35px; 
  }
}
</style>
<route lang="yaml">
  meta:
    layout: guest
    redirectIfLoggedIn: true
</route>